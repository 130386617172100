// Types
import { RootState } from 'types/RootState.types';

export const getPaymentApplePayPaymentSessionApiCallActiveSelector =
  (state: RootState) => state.payment?.applePayPaymentSessionApiCallActive ?? false;
export const getCheckPOSCreditEligibilityProcessingSelector =
  (state: RootState) => state.payment?.checkPOSCreditEligibilityProcessing ?? false;
export const getClearpayServiceUnavailableSelector =
  (state: RootState) => state.payment?.clearpayServiceUnavailable || false;
export const getClearpayStubsModalOpenSelector =
  (state: RootState) => state.payment?.clearpayStubsModalOpen || false;
export const getCreditCard3DSecureInfoSelector = (state: RootState) => state.payment?.creditCard3DSecureInfo;
export const getPaymentSubmitOrderErrorSelector = (state: RootState) => state.payment?.submitOrderError;
export const getGetPayPalClientTokenCallActiveSelector =
  (state: RootState) => state.payment?.getPayPalClientTokenCallActive || false;
export const getPayPalClientTokenSelector = (state: RootState) => state.payment?.payPalClientToken;
export const getPayPalStubsModalOpenSelector =
  (state: RootState) => state.payment?.payPalStubsModalOpen || false;
export const getGetPayPalClientTokenFailedSelector =
  (state: RootState) => state.payment?.getPayPalClientTokenFailed || false;
export const getPaymentGooglePayPaymentsClientHasBeenCreatedSelector =
  (state: RootState) => state.payment?.googlePayPaymentsClientHasBeenCreated || false;
export const getInitPaymentPageCallActiveSelector =
  (state: RootState) => state.payment?.initPaymentPageCallActive || false;
export const getPaymentGetPaymentPageCallActiveSelector =
  (state: RootState) => state.payment?.getPaymentPageCallActive || false;
export const getPaymentShowPartnerCardErrorSelector =
  (state: RootState) => state.payment?.showPartnerCardError || false;
export const getInvalidGooglePayBillingAddressSelector =
  (state: RootState) => state.payment?.invalidGooglePayBillingAddress;
export const getInvalidGooglePayBillingAddressResolverSelector =
  (state: RootState) => state.payment?.invalidGooglePayBillingAddressResolver;
export const getInvalidPayPalBillingAddressSelector = (state: RootState) => state.payment?.invalidPayPalBillingAddress;
export const getInvalidPayPalShippingAddressSelector =
  (state: RootState) => state.payment?.invalidPayPalShippingAddress;
export const getIsCardFormValidSelector = (state: RootState) => state.payment?.isCardFormValid ?? true;
export const getPartnerDiscountInvalidSelector = (state: RootState) => state.payment?.partnerDiscountInvalid || false;
export const getPaymentCardDeleteApiCallActiveSelector =
  (state: RootState) => state.payment?.paymentCardDeleteApiCallActive || false;
export const getPaymentCardDeleteApiCallFailedSelector =
  (state: RootState) => state.payment?.paymentCardDeleteApiCallFailed || false;
export const getPaymentCardDeletedSelector = (state: RootState) => state.payment?.paymentCardDeleted;
export const getPaymentWalletSelector = (state: RootState) => state.payment?.paymentWallet;
export const getPaymentPaypalNonceSelector = (state: RootState) => state.payment?.paypalNonce;
export const getPaymentCreditTypeGroupsSelector = (state: RootState) => state.payment?.creditTypeGroups;
export const getPaymentPosCreditEligibleSelector = (state: RootState) => state.payment?.posCreditEligible || false;
export const getPaymentNotSelectedErrorSelector = (state: RootState) => state.payment?.paymentNotSelectedError || false;
export const getPaymentProcessingSelector = (state: RootState) => state.payment?.paymentProcessing || false;
export const getPayPalServiceUnavailableSelector =
  (state: RootState) => state.payment?.payPalServiceUnavailable || false;
export const getPayPalPayLaterIsNotEligibleSelector =
  (state: RootState) => state.payment?.payPalPayLaterIsNotEligible || false;
export const getPayPalPayExpressCompatibilityChecksCompleteSelector =
  (state: RootState) => state.payment?.payPalPayExpressCompatibilityChecksComplete || false;
export const getShowMarketingPreferencesPromptSelector =
  (state: RootState) => state.payment?.showMarketingPreferencesPrompt || false;
export const getCanMakeGooglePayPaymentsSelector =
  (state: RootState) => state.payment?.canMakeGooglePayPayments || false;
export const getGooglePayPaymentsClientHasBeenCreatedSelector =
  (state: RootState) => state.payment?.googlePayPaymentsClientHasBeenCreated || false;
export const getPaymentPayPalPaymentRequestSelector = (state: RootState) => state.payment?.payPalPaymentRequest?.amount;
export const getSelectedPaymentTypeSelector = (state: RootState) => state.payment?.selectedPaymentType || undefined;
export const getSelectedSavedCardSelector = (state: RootState) => state.payment?.selectedSavedCard;
export const getShouldShowPaymentCardDeleteModalSelector =
  (state: RootState) => state.payment?.shouldShowPaymentCardDeleteModal || false;
export const getShowAddressBookModalSelector = (state: RootState) => state.payment?.showAddressBookModal || false;
export const getShowPayPalServiceUnavailableErrorSelector =
  (state: RootState) => state.payment?.showPayPalServiceUnavailableError || false;
export const getPaymentThreeDSMethodInfoSelector = (state: RootState) => state.payment?.threeDSMethodInfo;
export const getApplePayCompatibilityChecksComplete =
  (state: RootState) => state.payment?.applePayCompatibilityChecksComplete || false;
export const getAppShowExpressPaymentsFailedMessage =
  (state: RootState) => state.payment?.showExpressPaymentsFailedMessage || false;
export const getIsExpressPayment = (state: RootState) => state.payment?.isExpressPayment || false;
export const getGiftingPaymentDetailsFailed =
  (state: RootState) => state.payment?.getGiftingPaymentDetailsFailed || false;
export const getPromoCodeError = (state: RootState) => state.payment?.promoCodeError;
export const getGooglePayCardPaymentMethod = (state: RootState) => state.payment?.googlePayCardPaymentMethod;
export const getGooglePayStubsModalOpen = (state: RootState) => state.payment?.googlePayStubsModalOpen;
export const getKlarnaLoading = (state: RootState) => state.payment?.klarnaLoading;
export const getIsKlarnaPaymentInProgress = (state: RootState) => state.payment?.isKlarnaPaymentInProgress;
export const getKlarnaStubsModalOpenSelector = (state: RootState) => state.payment?.klarnaStubsModalOpen || false;
export const getKlarnaServiceUnavailableSelector =
  (state: RootState) => state.payment?.klarnaServiceUnavailable || false;
export const getKlarnaSdkScriptLoadedSelector =
  (state: RootState) => state.payment?.klarnaSdkScriptLoaded || false;
export const getClearpayMessageScriptLoadedSelector =
  (state: RootState) => state.payment?.clearpayMessageScriptLoaded || false;
export const getClearpayScriptLoadingSelector =
  (state: RootState) => state.payment?.clearpayScriptLoading || false;
export const getClearpayScriptLoadedSelector =
  (state: RootState) => state.payment?.clearpayScriptLoaded || false;
export const getPOSCreditApplicationStatusSelector =
  (state: RootState) => state.payment?.posCreditApplicationStatus;
